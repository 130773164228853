// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-hire-js": () => import("../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-js": () => import("../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-travels-js": () => import("../src/pages/travels.js" /* webpackChunkName: "component---src-pages-travels-js" */),
  "component---src-pages-van-js": () => import("../src/pages/van.js" /* webpackChunkName: "component---src-pages-van-js" */)
}

